import React from 'react';
import './SettingsMenu.css';

const SettingsMenu = ({ setTheme }) => {
    const changeTheme = (theme) => {
        setTheme(theme);
        document.documentElement.setAttribute('data-theme', theme);
    };

    return (
        <div className="settings-menu" id="settingsMenu" style={{ display: 'none' }}>
            <h3>Settings</h3>
            <div>
                <h4>Themes</h4>
                <div className="theme-circle" onClick={() => changeTheme('default')} data-theme="default" style={{ background: 'linear-gradient(45deg, #fff 0%, #9e9e9e 100%)' }}></div>
                <div className="theme-circle" onClick={() => changeTheme('dark')} data-theme="dark" style={{ background: 'linear-gradient(45deg, #363636 0%, #171717 100%)' }}></div>
                <div className="theme-circle" onClick={() => changeTheme('sunset')} data-theme="sunset" style={{ background: 'linear-gradient(45deg, #ff9a9e 0%, #fad0c4 99%, #fad0c4 100%)' }}></div>
                <div className="theme-circle" onClick={() => changeTheme('ocean')} data-theme="ocean" style={{ background: 'linear-gradient(45deg, #a1c4fd 0%, #c2e9fb 100%)' }}></div>
                <div className="theme-circle" onClick={() => changeTheme('forest')} data-theme="forest" style={{ background: 'linear-gradient(45deg, #667db6 0%, #0082c8 50%, #0082c8 100%)' }}></div>
                <div className="theme-circle" onClick={() => changeTheme('sky')} data-theme="sky" style={{ background: 'linear-gradient(45deg, #89f7fe 0%, #66a6ff 100%)' }}></div>
                <div className="theme-circle" onClick={() => changeTheme('royal')} data-theme="royal" style={{ background: 'linear-gradient(45deg, #5f72be 0%, #9b23ea 100%)' }}></div>
                <div className="theme-circle" onClick={() => changeTheme('candy')} data-theme="candy" style={{ background: 'linear-gradient(45deg, #ffecd2 0%, #fcb69f 100%)' }}></div>
            </div>
            <div>
                <button onClick={() => changeTheme('default')} className="reset-button">Reset to Defaults</button>
            </div>
        </div>
    );
};

export default SettingsMenu;
