import React, { useEffect, useState } from 'react';
import './LogTable.css';

const LogTable = ({ show }) => {
    const [log, setLog] = useState([]);

    useEffect(() => {
        const storedLog = JSON.parse(localStorage.getItem('calcLog')) || [];
        setLog(storedLog);
    }, []);

    if (!show) {
        return null;
    }

    return (
        <div>
            <table id="logTable">
                <thead>
                    <tr>
                        <th>PERIOD 2</th>
                        <th>PERIOD 1</th>
                        <th>CALC</th>
                    </tr>
                </thead>
                <tbody>
                    {log.map((entry, index) => (
                        <tr key={index}>
                            <td>{entry.time1}</td>
                            <td>{entry.time2}</td>
                            <td>{entry.result}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default LogTable;
