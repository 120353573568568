import React, { useState, useEffect, useCallback } from 'react';
import './App.css'; // Import the CSS file
import TimeInput from './components/TimeInput';
import Header from './components/Header';
import SettingsMenu from './components/SettingsMenu';
import LogTable from './components/LogTable';

const App = () => {
    const [time1, setTime1] = useState('');
    const [time2, setTime2] = useState('');
    const [result, setResult] = useState('-');
    const [showLog, setShowLog] = useState(false);

    useEffect(() => {
        const savedTheme = localStorage.getItem('theme') || 'dark';
        applyTheme(savedTheme);
    }, []);

    const calculateResult = useCallback(() => {
        if (time1 && time2) {
            const totalMinutes1 = timeToMinutes(time1);
            const totalMinutes2 = timeToMinutes(time2);
            const diffMinutes = totalMinutes1 - totalMinutes2;
            setResult(minutesToTime(diffMinutes));
        } else {
            setResult('-');
        }
    }, [time1, time2]);

    useEffect(() => {
        calculateResult();
    }, [time1, time2, calculateResult]);

    const handleCopy = (id) => {
        const element = document.getElementById(id);
        let text = '';
        if (element) {
            text = element.tagName === 'SPAN' ? element.textContent : element.value;
            navigator.clipboard.writeText(text).then(() => {
                const button = document.querySelector(`button[data-action="copy"][data-target="${id}"]`);
                button.textContent = 'Copied!';
                setTimeout(() => {
                    button.textContent = 'Copy';
                }, 2000);
            });
        }
    };

    const handlePaste = (id) => {
        navigator.clipboard.readText().then(text => {
            if (id === 'time1') {
                setTime1(text);
            } else {
                setTime2(text);
            }
        });
    };

    const timeToMinutes = (time) => {
        const separatorMatch = time.match(/[^0-9]/);
        if (!separatorMatch) {
            return 0;
        }
        const separator = separatorMatch[0];
        const parts = time.split(separator);
        const hours = parseInt(parts[0], 10) || 0;
        const minutes = parseInt(parts[1], 10) || 0;
        return hours * 60 + minutes;
    };

    const minutesToTime = (totalMinutes) => {
        const hours = Math.floor(totalMinutes / 60);
        const minutes = totalMinutes % 60;
        return `${hours} Hour(s) ${minutes.toString().padStart(2, '0')} Minute(s)`;
    };

    const logCalculation = () => {
        const time1 = document.getElementById('time1').value;
        const time2 = document.getElementById('time2').value;
        const result = document.getElementById('result').textContent;
        if (result !== '-') {
            const logEntry = { time1, time2, result };
            const log = JSON.parse(localStorage.getItem('calcLog')) || [];
            log.push(logEntry);
            localStorage.setItem('calcLog', JSON.stringify(log));
            updateLogTable();
        }
    };

    const updateLogTable = () => {
        const log = JSON.parse(localStorage.getItem('calcLog')) || [];
        const tableBody = document.getElementById('logTable')?.getElementsByTagName('tbody')[0];
        if (tableBody) {
            tableBody.innerHTML = '';
            log.forEach(entry => {
                const row = tableBody.insertRow();
                row.insertCell(0).textContent = entry.time1;
                row.insertCell(1).textContent = entry.time2;
                row.insertCell(2).textContent = entry.result;
            });
        }
    };

    const toggleSettingsMenu = () => {
        const menu = document.getElementById('settingsMenu');
        menu.style.display = (menu.style.display === 'none' || !menu.style.display) ? 'block' : 'none';
    };

    const toggleLogVisibility = () => {
        setShowLog(!showLog);
    };

    const applyTheme = (theme) => {
        const root = document.documentElement;
        const themes = document.querySelectorAll('.theme-circle');
        themes.forEach(circle => {
            circle.classList.remove('active');
            if (circle.getAttribute('data-theme') === theme) {
                circle.classList.add('active');
            }
        });

        switch(theme) {
            case 'dark':
                root.style.setProperty('--background-color', '#333');
                root.style.setProperty('--text-color', '#fff');
                root.style.setProperty('--button-bg', '#555');
                root.style.setProperty('--input-bg', '#666');
                root.style.setProperty('--input-text', '#fff');
                root.style.setProperty('--gradient', 'linear-gradient(90deg, rgb(154, 255, 251) 0%, rgb(67, 207, 202) 50%, rgb(81, 146, 143) 100%)');
                break;
            case 'sunset':
                root.style.setProperty('--background-color', '#ff9a9e');
                root.style.setProperty('--text-color', '#333');
                root.style.setProperty('--button-bg', '#fad0c4');
                root.style.setProperty('--input-bg', '#666');
                root.style.setProperty('--input-text', '#fff');
                root.style.setProperty('--gradient', 'linear-gradient(90deg, rgb(154, 255, 251) 0%, rgb(67, 207, 202) 50%, rgb(81, 146, 143) 100%)');
                break;
            case 'ocean':
                root.style.setProperty('--background-color', '#a1c4fd');
                root.style.setProperty('--text-color', '#000');
                root.style.setProperty('--button-bg', '#c2e9fb');
                root.style.setProperty('--input-bg', '#666');
                root.style.setProperty('--input-text', '#fff');
                root.style.setProperty('--gradient', 'linear-gradient(90deg, rgb(116, 151, 255) 0%, rgb(146, 240, 255) 50%, rgb(0, 17, 172) 100%)');
                break;
            case 'forest':
                root.style.setProperty('--background-color', '#667db6');
                root.style.setProperty('--text-color', '#fff');
                root.style.setProperty('--button-bg', '#0082c8');
                root.style.setProperty('--input-bg', '#666');
                root.style.setProperty('--input-text', '#fff');
                root.style.setProperty('--gradient', 'linear-gradient(90deg, rgb(116, 151, 255) 0%, rgb(146, 240, 255) 50%, rgb(0, 17, 172) 100%)');
                break;
            case 'sky':
                root.style.setProperty('--background-color', '#89f7fe');
                root.style.setProperty('--text-color', '#fff');
                root.style.setProperty('--button-bg', '#66a6ff');
                root.style.setProperty('--input-bg', '#666');
                root.style.setProperty('--input-text', '#fff');
                root.style.setProperty('--gradient', 'linear-gradient(90deg, rgb(143, 248, 101) 0%, rgb(143, 248, 101) 50%, rgb(143, 248, 101) 100%)');
                break;
            case 'royal':
                root.style.setProperty('--background-color', '#5f72be');
                root.style.setProperty('--text-color', '#fff');
                root.style.setProperty('--button-bg', '#9b23ea');
                root.style.setProperty('--input-bg', '#666');
                root.style.setProperty('--input-text', '#fff');
                root.style.setProperty('--gradient', 'linear-gradient(90deg, rgb(116, 151, 255) 0%, rgb(146, 240, 255) 50%, rgb(0, 17, 172) 100%)');
                break;
            case 'candy':
                root.style.setProperty('--background-color', '#ffecd2');
                root.style.setProperty('--text-color', '#333');
                root.style.setProperty('--button-bg', '#fcb69f');
                root.style.setProperty('--input-bg', '#666');
                root.style.setProperty('--input-text', '#fff');
                root.style.setProperty('--gradient', 'linear-gradient(90deg, rgb(116, 151, 255) 0%, rgb(146, 240, 255) 50%, rgb(0, 17, 172) 100%)');
                break;
            default:
                root.style.setProperty('--background-color', '#fff');
                root.style.setProperty('--text-color', '#000');
                root.style.setProperty('--button-bg', '#f0f0f0');
                root.style.setProperty('--input-bg', '#fff');
                root.style.setProperty('--input-text', '#000');
                root.style.setProperty('--gradient', 'linear-gradient(90deg, rgb(116, 151, 255) 0%, rgb(146, 240, 255) 50%, rgb(0, 17, 172) 100%)');
                break;
        }
    };

    return (
        <div className="container">
            <Header />
            <button onClick={toggleSettingsMenu} style={{ position: 'absolute', top: 0, left: 0 }}>Settings</button>
            <SettingsMenu setTheme={applyTheme} />
            <TimeInput id="time1" placeholder="PERIOD 2" value={time1} onCopy={handleCopy} onPaste={handlePaste} onChange={(e) => setTime1(e.target.value)} />
            <TimeInput id="time2" placeholder="PERIOD 1" value={time2} onCopy={handleCopy} onPaste={handlePaste} onChange={(e) => setTime2(e.target.value)} />
            <div>Result: <span id="result" className="russo-font">{result}</span></div>
            <button data-action="copy" data-target="result" onClick={() => handleCopy('result')}>Copy</button>
            <button onClick={logCalculation}>Log Calculation</button>
            <button onClick={toggleLogVisibility}>Show/Hide Log</button>
            <LogTable show={showLog} />
        </div>
    );
};

export default App;
