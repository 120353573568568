import React from 'react';
import './TimeInput.css';

const TimeInput = ({ id, placeholder, value, onCopy, onPaste, onChange }) => {
    return (
        <div>
            <input
                type="text"
                id={id}
                className="time-input"
                placeholder={placeholder}
                value={value}
                onChange={onChange}
            />
            <button data-action="copy" data-target={id} onClick={() => onCopy(id)}>Copy</button>
            <button onClick={() => onPaste(id)}>Paste</button>
        </div>
    );
};

export default TimeInput;
